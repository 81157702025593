// ==========================================================================//
//
// [パーツ情報 - 変数]
//
// ========================================================================== //

// フォントファミリー
$font-style: 'Noto Sans JP', 'Hiragino Sans', 'ヒラギノ角ゴ Pro', 'Hiragino Kaku Gothic Pro', 'メイリオ', 'Meiryo', sans-serif;
//$font-style: '游ゴシック体', YuGothic, '游ゴシック', 'Yu Gothic', 'メイリオ', sans-serif;
//$font-style: 'Yu Mincho', 'YuMincho', serif;

// webfont
/*$font-noto-sans-jp: true; // true or false
@if $font-noto-sans-jp {
  $font-style: 'Noto Sans JP', sans-serif;
}*/

$font-teko: 'Teko', sans-serif;

// ブレイクポイント
$default-breakpoint: 'md';
$breakpoint-sm: 480; 
$breakpoint-md: 897; 
$breakpoint-lg: 1024;
$breakpoint-up: (
  'sm': 'screen and (min-width: #{$breakpoint-sm}px)',
  'md': 'screen and (min-width: #{$breakpoint-md}px)',
  'lg': 'screen and (min-width: #{$breakpoint-lg}px)',
) !default;

// max-width
$breakpoint-down: (
  'sm': 'screen and (max-width: #{$breakpoint-sm - 1}px)',
  'md': 'screen and (max-width: #{$breakpoint-md - 1}px)',
  'lg': 'screen and (max-width: #{$breakpoint-lg - 1}px)',
) !default;

// body背景
$body-background: #fff;

// コンテンツ幅
$contents-width: 1050px;

// コンテンツ余白
$contents-gatter-sp: 15px;
$contents-gatter-pc: 25px;

// デフォルトフォントサイズ
$fontsize-sp: 1.3rem;
$fontsize-pc: 1.5rem;

// デフォルトフォントカラー
$font-color: #1A1311;

// デフォルトリンクカラー
$link-color: #0062a0;

// プレースホルダーカラー
$placeholder-color: #999;

// アニメーションスピード
$transition: .3s;

// 透明度
$opacity: .7;

// 影
$shadow: 0 0 10px rgba(113, 113, 113, 0.5);

$first-letter-en: #DA003E;
$color-black: #1A1311;
$color-white: #fff;
$color-red: #DA003E;
$color-green: #32C7D3;
$color-blue: #EAF9FF;
$color-pink: #FA7777;
$color-gray: #DCDCDC;