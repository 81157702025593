$root: "";
// [環境設定]
@import 'foundation/_variables';
@import 'foundation/_mixin';

/* ==========================================================================//
//
// [個別スタイル]
//
// ========================================================================== */

.p-top{
  .p-acheivement{
    margin-top: 164px;
    @include mq-up(){
      width: calc(100% - 100px);
      margin: 164px auto 0;
    }
    @include mq-down(){
      margin-top: 74px;
    }
    .c-box-achievement{
      &__item{
        padding-bottom: 47px;
        @include mq-down(){
          padding-bottom: 41px;
        }
        &:not(:nth-child(1), :nth-child(2)){
          margin-top: 0;
        }
        + .c-box-achievement__item{
          @include mq-down(){
            margin-top: 19px;
          }
        }
        &.-bg-blue{
          @include mq-down(){
            background: none;
            position: relative;
          }
          &.-space{
            @include mq-down(){
              margin-top: 0;
              padding-top: 0;
            }
            &::after{
              @include mq-down(){
                content: '';
                width: 79%;
                height: calc(100% - 51px);
                display: block;
                position: absolute;
                left: unset;
                top: unset;
                right: 0;
                bottom: 0;
                background: #EAF9FF;
                z-index: -1;
              }
            }
          }
          &::after{
            @include mq-down(){
              content: '';
              width: 79%;
              height: 100%;
              display: block;
              position: absolute;
              left: 0;
              top: 0;
              background: $color-blue;
              z-index: -1;
            }
          }
        }
      }
      &__title{
        &::before{
          @include mq-down(){
            display: none;
          }
        }
        &::after{
          @include mq-down(){
            content: '';
            width: 79%;
            height: calc(100% - 26px);
            display: block;
            position: absolute;
            left: 0;
            bottom: 0;
            background: #EAF9FF;
            z-index: -1;
          }
        }
      }
      &__title-in{
        .c-title{
          font-size: 9rem;
          font-family: $font-teko;
          font-weight: 700;
          @include mq-down(){
            font-size: 4.8rem;
            line-height: 0.9;
          }
        }
        .c-sub-title{
          font-family: $font-style;
          margin-top: -5px;
          @include mq-down(){
            font-size: 1.5rem;
            line-height: 1.3;
            margin-top: 1px;
          }
        }
      }
      &__item-in{
        .c-image{
          @include mq-down(){
            text-align: center;
          }
          img.c-image{
            @include mq-down(){
              width: 70%;
              margin: 10px auto 0;
            }
          }
        }
      }
      .c-box-detail{
        @include mq-down(){
          margin-top: 20px;
        }
      }
    }
  }
  .p-contact-us{
    margin-top: 130px;
    @include mq-down(){
      margin-top: 50px;
    }
  }
  .p-reason-list{
    margin-top: 147px;
    @include mq-down(){
      margin-top: 59px;
    }
    .c-box-title{
      .c-heading-md{
        @include mq-up(){
          font-size: 2.4rem;
        }
      }
    }
    .c-heading-lg{
      @include mq-down(){
        font-size: 4.8rem;
        text-align: center;
        line-height: 0.9;
      }
    }
    .c-heading-md{
      @include mq-down(){
        font-size: 1.5rem;
        text-align: center;
        margin-top: 0;
      }
    }
    .c-box-content-grid-type{
      padding-bottom: 0;
      &::before{
        display: none;
      }
      &__card{
        margin-top: 50px;
        @include mq-down(){
          margin-top: 29px;
        }
        &:nth-child(3), &:nth-child(4){
          margin-top: 26px;
        }
        .c-box-detail{
          @include mq-down(){
            padding: 10px 0 18px;
          }
        }
        .c-title{
          @include mq-down(){
            padding: 0 15px 0;
          }
        }
        .c-label{
          @include mq-down(){
            margin-left: 0px;
            width: 80.8%;
          }
        }
        & + .c-box-content-grid-type__card {
          @include mq-down(){
            margin-top: 23px;
          }
        }
      }
      &__image{
        padding-top: 30px;
        padding-right: 30px;
        position: relative;
        @include mq-down(){
          padding-top: 20px;
          padding-right: 20px;
        }
        &::after{
          content: '';
          width: calc(100% - 30px);
          height: calc(100% - 20px);
          display: block;
          position: absolute;
          z-index: -1;
          background: $color-blue;
          top: 0;
          left: 30px;
          right: -30px;
          bottom: 20px;
          @include mq-down(){
            width: calc(100% - 20px);
            left: 21px;
          }
        }
      }
      .c-box-detail{
        .c-link{
          text-align: center;
          margin-top: 20px;
        }
      }
    }
  }
  .p-price{
    margin-top: 150px;
    @include mq-down(){
      margin-top: 61px;
    }
    .c-heading-lg{
      @include mq-down(){
        font-size: 4.8rem;
        line-height: 0.9;
      }
    }
    .c-heading-md{
      @include mq-down(){
        font-size: 1.5rem;
        margin-top: 0px;
      }
    }
    .c-box-slider{
      margin: 30px auto 0;
      position: relative;
      @include mq-down(){
        transform: scale(0.9);
        width: 100%;
        margin: 0 auto;
        margin-left: -4%;
      }
      .c-box-price-object__card{
        @include mq-down(){
          padding: 0 10px;
        }
      }
      .slick-list{
        @include mq-down(){
          overflow: visible;
        }
      }
      .slick-arrow{
        width: 60px;
        height: 60px;
        border-radius: 110px;
        text-indent: 100%;
        white-space: nowrap;
        overflow: hidden;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        background: #1A1311;
        color: #1A1311;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
      }
      .slick-prev{
        left: -30px;
        z-index: 1;
        &::before{
          content: '';
          background: #1A1311 url(/assets/_img/common/ico_arrow-2.svg) no-repeat center center;
          background-size: 14px 28px;
          display: block;
          width: 14px;
          height: 28px;
          text-indent: 100%;
          white-space: nowrap;
          overflow: hidden;
          position: absolute;
          transform: rotate(180deg);
        }
      }
      .slick-next{
        right: -30px;
        z-index: 1;
        &::before{
          content: '';
          background: #1A1311 url(/assets/_img/common/ico_arrow-2.svg) no-repeat center center;
          background-size: 14px 28px;
          display: block;
          width: 14px;
          height: 28px;
          text-indent: 100%;
          white-space: nowrap;
          overflow: hidden;
          position: absolute;
        }
      }
    }
    .c-box-price-object{
      margin-top: 0;
      &__card{
        width: 100%;
        padding: 0 10px;
        .c-title{
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          @include mq-down(){
            line-height: 1.55;
            padding: 6.35% 20% 6.35% 7.9%;
          }
          &::after{
            @include mq-down(){
              right: 13px;
            }
          }
        }
      }
    }
    .c-link{
      margin-top: 20px;
      @include mq-down(){
        margin-top: 7px;
      }
      .c-box-button-small__link{
        @include mq-down(){
          width: 79.55%;
        }
      }
    }
  }
  .p-parallax{
    width: calc(100% - 100px);
    margin: 56px auto 0;
  }
  .p-access{
    margin-top: 144px;
    padding-bottom: 107px;
    @include mq-down(){
      margin-top: 90px;
      padding-bottom: 0;
    }
    .c-heading-lg{
      @include mq-down(){
        font-size: 4.8rem;
        line-height: 0.9;
      }
    }
    .c-heading-md{
      @include mq-down(){
        font-size: 1.5rem;

      }
    }
    &__in{
      width: calc(100% - 100px);
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      position: relative;
      @include mq-down(){
        width: calc(100% - 30px);
        margin: 0 auto;
      }
      &::before{
        content: '';
        width: 100%;
        height: 410px;
        display: block;
        position: absolute;
        top: 120px;
        background: $color-blue;
        z-index: -1;
        min-width: 0;
        @include mq-down(){
          top: 18px;
          height: 104.2%;
          left: -16px;
          width: 86%;
        }
      }
    }
    &__box-detail{
      width: 50%;
      flex: 0 1 50%;
      position: relative;
      @include mq-down(){
        width: 100%;
        flex: 0 1 100%;
      }
      &.-map{
        @include mq-up(){
          flex: 0;
          position: absolute;
          left: 50px;
          top: 0;
          bottom: 0;
          width: calc(50% - 50px);
        }
      }
      .c-heading-lg{
        @include mq-down(){
          text-align: center;
        }
      }
      .c-heading-md{
        @include mq-down(){
          text-align: center;
        }
      }
      .c-map{
        padding-top: 52.365%;
        position: static;
        @include mq-down(){
          width: 104.3%;
          flex: 0 1 104.3%;
          position: relative;
          height: auto;
          left: -15px;
          margin-top: 30px;
        }
        iframe{
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
        }
      }
      &.text{
        padding-top: 16px;
        width: 50%;
        flex: 0 1 50%;
        margin-left: 50%;
        @include mq-down(){
          padding: 0;
          width: 100%;
          flex: 0 1 100%;
          margin-left: 0;
        }
        .c-detail{
          padding-top: 51px;
          @include mq-down(){
            padding-top: 20px;
          }
          @include mq-up(){
            padding-left: 50px;
          }
          .c-sub-title{
            font-size: 2rem;
            line-height: 1.5;
            display: inline-block;
            border-bottom: 2px solid $color-black;
            padding-bottom: 2px;
            font-weight: 700;
            @include mq-down(){
              font-size: 1.5rem;
              line-break: 1.55;
            }
            + .c-text{
              margin-top: 6px;
              @include mq-down(){
                margin-top: 11px;
              }
            }
          }
          .c-text{
            line-height: 1.333;
            &:not(:first-of-type){
              margin-top: 15px;
              @include mq-down(){
                margin-top: 10px;
              }
            }
          }
          .c-tel{
            font-family: $font-teko;
            font-size: 2.8rem;
            font-weight: 700;
            margin-top: 4px;
            padding-left: 27px;
            background: url(/assets/_img/common/ico_phone-1.svg) no-repeat left center;
          }
          .c-link{
            margin-top: 30px;
            @include mq-down(){
              text-align: center;
              margin-top: 11px;
            }
            .c-box-button-small__link{
              padding: 18px 61px;
              @include mq-down(){
                padding: 15px 0;
              }
            }
          }
        }
      }
      .c-box-contact-us__button{
        @include mq-down(){
          margin-top: 14px;
          width: calc(100% - 45px);
          padding: 0;
          text-align: center;
          margin-left: auto;
          margin-right: auto;
        }
        .c-button-contact--tel{
          @include mq-down(){
            min-height: 50px;
          }
        }
        .c-button-contact__in{
          @include mq-down(){
            padding: 10px 30px 10px 56px;
            background-position: 15px 3px;
          }
          .c-button-contact--tel{
            @include mq-down(){
              min-height: 50px;
            }
          }
        }
      }
    }
  }
  .p-sns{
    margin-top: 89px;
    @include mq-down(){
      margin-top: 73px;
    }
    &__title{
      @include mq-down(){
        display: inline-block;
        margin: 0 auto;
        text-align: left;
      }
    }
    &__instragram{
      @include mq-down(){
        text-align: center;
      }
    }
    .c-heading-lg{
      display: inline-block;
      position: relative;
      &::before{
        content: '';
        display: block;
        width: 57px;
        height: 57px;
        background: url(/assets/_img/common/instagram_logo-01.svg) no-repeat;
        background-size: 100%;
        position: absolute;
        right: -72px;
        top: 15px;
        @include mq-down(){
          width: 31px;
          height: 31px;
          right: -39px;
          top: 4px;
        }
      }
    }
    &__content{
      @include mq-down(){
        margin-top: 30px;
      }
    }
    + .p-contact-us{
      @include mq-down(){
        margin-top: 56px;
      }
    }
  }
  .c-heading-md{
    font-size: 2.8rem;
    line-height: 1.428;
    margin-top: -10px;
    @include mq-down(){
      font-size: 1.5rem;
      margin-top: 0;
    }
  }
  .p-welcome{
    &__in{
      position: relative;
      &::after{
        @include mq-down(){
          content: '';
          width: 79%;
          height: calc(100% - 139px - 112px);
          display: block;
          background: #EAF9FF;
          position: absolute;
          bottom: 112px;
          left: 0;
          z-index: -1;
        }
      }
      .c-heading-lg.en{
        @include mq-up(){
          letter-spacing: -0.036em;
        }
      }
    }
    &__box-detail{
      &::after{
        @include mq-down(){
          display: none;
        }
      }
    }
  }
  + .l-footer{
    @include mq-down(){
      margin-top: 47px;
    }
  }

  .slick-slide img {

  }
  .slick-slide .media{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .c-box-achievement__item-in .c-box-label{
    max-height: 48px;
    @include mq-down{
      height: 36px;
    }
  }
}

.u-main-slider{
  height: 100vh;
  opacity: 0;
  transition: opacity 1s ease-in;
  @include mq-down(){
    height: auto;
  }
  &.is-active {
    opacity: 1;
  }

  .slider-track { 
    transition: transform 9.5s cubic-bezier(0.215, 0.61, 0.355, 1);
    overflow: hidden;
  }

  /* item */
  &__item {
    position: relative;
    z-index: 1;
    overflow: hidden;
  }

  &__bg {
    width: 100vw;
    height: 100vh;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    transition: transform 9.5s cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: scale(1.05);
    @include mq-down(){
      height: auto;
      padding-top: 100%;
    }
  }

  .on &__bg {
    transform: scale(1);
  }
}