// ==========================================================================//
//
// [パーツ情報 - mixin]
//
// ========================================================================== //

// max-widthレスポンシブ
@mixin responsive-down($class, $bp: $breakpoint-down) {
  @each $suffix, $value in $bp {
    @media #{$value} {
      #{$class}-#{$suffix}--down {
        @content;
      }
    }
  }
}

// min-widthレスポンシブ
@mixin responsive-up($class, $bp: $breakpoint-up) {
  #{$class} {
    @content;
  }
  @each $suffix, $value in $bp {
    @media #{$value} {
      #{$class}-#{$suffix} {
        @content;
      }
    }
  }
}

// min-widthメディアクエリ
@mixin mq-up($breakpoint: $default-breakpoint) {
  @if map-has-key($breakpoint-up, $breakpoint) {
    @media #{inspect(map-get($breakpoint-up, $breakpoint))} {
      @content;
    }
  } @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
    + "Please make sure it is defined in `$_breakpoint-up` map.";
  }
}

// max-widthメディアクエリ
@mixin mq-down($breakpoint: $default-breakpoint) {
  @if map-has-key($breakpoint-down, $breakpoint) {
    @media #{inspect(map-get($breakpoint-down, $breakpoint))} {
      @content;
    }
  } @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
    + "Please make sure it is defined in `$_breakpoint-down` map.";
  }
}

// 非表示
@mixin hidden($speed:$transition) {
  max-height: 0;
  overflow: hidden;
  transition: max-height $speed;
}
